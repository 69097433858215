import React from "react";

import { IconProps } from "./IconProps";

export const ChevronDown = ({ color, className }: IconProps) => {
  return (
    <svg
      width='14'
      height='10'
      viewBox='0 0 14 10'
      fill={color || "#4033D0"}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M6.46875 8.9375L0.375 2.875C0.09375 2.5625 0.09375 2.09375 0.375 1.8125L1.09375 1.09375C1.375 0.8125 1.84375 0.8125 2.15625 1.09375L7 5.90625L11.8125 1.09375C12.125 0.8125 12.5938 0.8125 12.875 1.09375L13.5938 1.8125C13.875 2.09375 13.875 2.5625 13.5938 2.875L7.5 8.9375C7.21875 9.21875 6.75 9.21875 6.46875 8.9375Z' />
    </svg>
  );
};
