import React from "react";

import { IconProps } from "./IconProps";

export const Star = ({ color, className }: IconProps) => {
  return (
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill={color || "currentColor"}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M8.02823 3.88869L11.2374 4.35996C11.5067 4.40484 11.7311 4.58438 11.8209 4.85368C11.9106 5.10053 11.8433 5.39228 11.6413 5.57181L9.30741 7.8833L9.86845 11.1598C9.91333 11.4291 9.80112 11.6984 9.5767 11.8555C9.35229 12.035 9.06055 12.035 8.81369 11.9228L5.94116 10.3743L3.04619 11.9228C2.82177 12.035 2.50759 12.035 2.30562 11.8555C2.0812 11.6984 1.96899 11.4291 2.01388 11.1598L2.55247 7.8833L0.218545 5.57181C0.0165699 5.39228 -0.050755 5.10053 0.0390115 4.85368C0.128778 4.58438 0.353194 4.40484 0.622494 4.35996L3.85409 3.88869L5.29035 0.903949C5.40256 0.657091 5.64942 0.5 5.94116 0.5C6.21046 0.5 6.45732 0.657091 6.56953 0.903949L8.02823 3.88869Z' />
    </svg>
  );
};
