import React from "react";

export const Chart = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      fill='none'
      viewBox='0 0 22 22'
      className={className}
    >
      <path
        stroke='#4033D0'
        strokeWidth='2.223'
        d='M21.256 1.269H1.25v18.895h18.895v-7.225'
      ></path>
      <path fill='#4033D0' d='M21.265 10.229L15.7 6.554l5.54-2.336.025 6.01z'></path>
      <path
        stroke='#4033D0'
        strokeWidth='2.223'
        d='M1.25 16.496l5.414-6.498c1.396-1.674 4.044-1.402 5.07.521v0c1.064 1.996 3.848 2.195 5.186.371l3.225-4.398'
      ></path>
    </svg>
  );
};
