import React from "react";

import { IconProps } from "./IconProps";

export const HokenSavingsMini = ({ color, className }: IconProps) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill={color || "#5BFCB8"}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M1.6875 0.375H6.92578C7.52344 0.375 8.08594 0.621094 8.50781 1.04297L14.6953 7.23047C15.5742 8.10938 15.5742 9.55078 14.6953 10.4297L10.0195 15.1055C9.14062 15.9844 7.69922 15.9844 6.82031 15.1055L0.632812 8.91797C0.210938 8.49609 0 7.93359 0 7.33594V2.0625C0 1.14844 0.738281 0.375 1.6875 0.375ZM3.9375 5.4375C4.53516 5.4375 5.0625 4.94531 5.0625 4.3125C5.0625 3.71484 4.53516 3.1875 3.9375 3.1875C3.30469 3.1875 2.8125 3.71484 2.8125 4.3125C2.8125 4.94531 3.30469 5.4375 3.9375 5.4375Z' />
    </svg>
  );
};
