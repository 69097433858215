import React from "react";

import { IconProps } from "./IconProps";

export const BackArrow = ({ color, className }: IconProps) => {
  return (
    <svg
      width='26'
      height='14'
      viewBox='0 0 26 14'
      className={className}
      fill={color || "currentColor"}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7.59577 1.40301C7.59564 1.30566 7.56709 1.21047 7.51363 1.12912C7.46018 1.04776 7.38413 0.983779 7.29483 0.945022C7.20553 0.906265 7.10686 0.894419 7.01093 0.910938C6.91499 0.927456 6.82596 0.97162 6.75477 1.03801L0.762768 6.63501C0.712812 6.68178 0.672989 6.73831 0.645764 6.80109C0.618539 6.86388 0.604492 6.93158 0.604492 7.00001C0.604492 7.06844 0.618539 7.13615 0.645764 7.19893C0.672989 7.26171 0.712812 7.31824 0.762768 7.36501L6.75477 12.962C6.826 13.0281 6.91494 13.072 7.01071 13.0883C7.10648 13.1046 7.20493 13.0927 7.29403 13.054C7.38314 13.0153 7.45903 12.9514 7.51244 12.8703C7.56585 12.7891 7.59446 12.6942 7.59477 12.597V7.58801H25.6278V6.41201H7.59577V1.40301Z' />
    </svg>
  );
};
