import React from "react";

import { IconProps } from "./IconProps";

export const Hotel = ({ color, className }: IconProps) => {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill={color || "#000"}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M14 0.75V2.0625H13.125V13.4375H14V14.75H0V13.4375H0.875V2.0625H0V0.75H14H0H14ZM11.8125 13.4375V2.0625H2.1875V13.4375H5.6875V11.25H4.375C4.39323 10.5026 4.64844 9.88281 5.14062 9.39062C5.63281 8.89844 6.2526 8.64323 7 8.625C7.7474 8.64323 8.36719 8.89844 8.85938 9.39062C9.35156 9.88281 9.60677 10.5026 9.625 11.25H8.3125V13.4375H11.8125H8.3125H11.8125ZM5.25 3.375V5.125H3.5V3.375H5.25H3.5H5.25ZM6.125 3.375H7.875V5.125H6.125V3.375V5.125V3.375ZM10.5 3.375V5.125H8.75V3.375H10.5H8.75H10.5ZM3.5 6H5.25V7.75H3.5V6V7.75V6ZM7.875 6V7.75H6.125V6H7.875H6.125H7.875ZM8.75 6H10.5V7.75H8.75V6V7.75V6Z'></path>
    </svg>
  );
};
