import React from "react";

export const CircleCheck = () => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 7.125C0 3.54492 2.89453 0.625 6.5 0.625C10.0801 0.625 13 3.54492 13 7.125C13 10.7305 10.0801 13.625 6.5 13.625C2.89453 13.625 0 10.7305 0 7.125ZM9.41992 6.00781C9.69922 5.72852 9.69922 5.29688 9.41992 5.01758C9.14062 4.73828 8.70898 4.73828 8.42969 5.01758L5.6875 7.75977L4.54492 6.64258C4.26562 6.36328 3.83398 6.36328 3.55469 6.64258C3.27539 6.92188 3.27539 7.35352 3.55469 7.63281L5.17969 9.25781C5.45898 9.53711 5.89062 9.53711 6.16992 9.25781L9.41992 6.00781Z'
        fill='#00AD64'
      />
    </svg>
  );
};
