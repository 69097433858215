import React from "react";

import { IconProps } from "./IconProps";

export const User = ({ color, className }: IconProps) => {
  return (
    <svg
      width='13'
      height='16'
      viewBox='0 0 15 18'
      fill={color || "currentColor"}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M10.5176 10.5938C12.9746 10.5938 15 12.6191 15 15.0762V15.9062C15 16.8027 14.2695 17.5 13.4062 17.5H1.71875C0.822266 17.5 0.125 16.8027 0.125 15.9062V15.0762C0.125 12.6191 2.11719 10.5938 4.57422 10.5938C5.53711 10.5938 5.96875 11.125 7.5625 11.125C9.12305 11.125 9.55469 10.5938 10.5176 10.5938ZM13.4062 15.9062V15.0762C13.4062 13.4824 12.1113 12.1875 10.5176 12.1875C10.0195 12.1875 9.25586 12.7188 7.5625 12.7188C5.83594 12.7188 5.07227 12.1875 4.57422 12.1875C2.98047 12.1875 1.71875 13.4824 1.71875 15.0762V15.9062H13.4062ZM7.5625 10.0625C4.90625 10.0625 2.78125 7.9375 2.78125 5.28125C2.78125 2.6582 4.90625 0.5 7.5625 0.5C10.1855 0.5 12.3438 2.6582 12.3438 5.28125C12.3438 7.9375 10.1855 10.0625 7.5625 10.0625ZM7.5625 2.09375C5.80273 2.09375 4.375 3.55469 4.375 5.28125C4.375 7.04102 5.80273 8.46875 7.5625 8.46875C9.28906 8.46875 10.75 7.04102 10.75 5.28125C10.75 3.55469 9.28906 2.09375 7.5625 2.09375Z' />
    </svg>
  );
};
