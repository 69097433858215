import React from "react";

export const Envelope = () => {
  return (
    <svg
      width='12'
      height='9'
      viewBox='0 0 12 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.7656 2.97656C11.8594 2.90625 12 2.97656 12 3.09375V7.875C12 8.50781 11.4844 9 10.875 9H1.125C0.492188 9 0 8.50781 0 7.875V3.09375C0 2.97656 0.117188 2.90625 0.210938 2.97656C0.75 3.39844 1.42969 3.91406 3.82031 5.64844C4.3125 6 5.15625 6.77344 6 6.77344C6.82031 6.77344 7.6875 6 8.15625 5.64844C10.5469 3.91406 11.2266 3.39844 11.7656 2.97656ZM6 6C5.4375 6.02344 4.66406 5.32031 4.26562 5.03906C1.14844 2.78906 0.914062 2.57812 0.210938 2.01562C0.0703125 1.92188 0 1.75781 0 1.57031V1.125C0 0.515625 0.492188 0 1.125 0H10.875C11.4844 0 12 0.515625 12 1.125V1.57031C12 1.75781 11.9062 1.92188 11.7656 2.01562C11.0625 2.57812 10.8281 2.78906 7.71094 5.03906C7.3125 5.32031 6.53906 6.02344 6 6Z'
        fill='#E88F3E'
      />
    </svg>
  );
};
