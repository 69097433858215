import React from "react";

import { IconProps } from "./IconProps";

export const MapMarker = ({ color, className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8.5'
      height='11.5'
      fill={color || "currentColor"}
      viewBox='0 0 8.5 11.5'
      className={className}
    >
      <path d='M4.70455 11.7299C4.55934 11.91 4.3851 12 4.18182 12C3.97854 12 3.80429 11.91 3.65909 11.7299C3.26705 11.2348 2.78788 10.5672 2.22159 9.72701C1.6553 8.90183 1.1471 8.04664 0.69697 7.16145C0.261364 6.27626 0.0290404 5.49609 0 4.82094C0.0290404 3.59067 0.435606 2.57045 1.2197 1.76027C2.00379 0.950098 2.99116 0.530007 4.18182 0.5C5.37247 0.530007 6.35985 0.950098 7.14394 1.76027C7.92803 2.57045 8.3346 3.59067 8.36364 4.82094C8.3346 5.49609 8.10227 6.27626 7.66667 7.16145C7.21654 8.04664 6.70833 8.90183 6.14205 9.72701C5.57576 10.5672 5.09659 11.2348 4.70455 11.7299ZM4.18182 3.38063C3.65909 3.39563 3.25979 3.63568 2.9839 4.10078C2.72254 4.58089 2.72254 5.06099 2.9839 5.5411C3.25979 6.0062 3.65909 6.24625 4.18182 6.26125C4.70455 6.24625 5.10385 6.0062 5.37973 5.5411C5.6411 5.06099 5.6411 4.58089 5.37973 4.10078C5.10385 3.63568 4.70455 3.39563 4.18182 3.38063Z' />
    </svg>
  );
};
