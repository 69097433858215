import * as React from "react";

export const Clock = ({ fill = "#000000" }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width={14} height={17}>
    <path
      fill={fill}
      d='M256 0a256 256 0 1 1 0 512 256 256 0 1 1 0-512zm-24 120v136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z'
    />
  </svg>
);
