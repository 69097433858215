import React from "react";

import { IconProps } from "./IconProps";

export const BedAlt = ({ color, className }: IconProps) => {
  return (
    <svg
      width='18'
      height='14'
      viewBox='0 0 18 14'
      fill={color || "#000"}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M1.3125 1.28125V8.9375H7.875V3.90625V3.25H8.53125H14.2188C15.1484 3.26823 15.9232 3.58724 16.543 4.20703C17.1628 4.82682 17.4818 5.60156 17.5 6.53125V9.59375V12.4375V13.0938H16.1875V12.4375V10.25H8.53125H1.3125V12.2188V12.875H0V12.2188V9.59375V1.28125V0.625H1.3125V1.28125V0.625V1.28125ZM16.1875 6.53125C16.1693 5.96615 15.9779 5.5013 15.6133 5.13672C15.2487 4.77214 14.7839 4.58073 14.2188 4.5625H9.1875V8.9375H16.1875V6.53125V8.9375V6.53125ZM3.5 5.65625C3.51823 6.07552 3.70052 6.39453 4.04688 6.61328C4.41146 6.79557 4.77604 6.79557 5.14062 6.61328C5.48698 6.39453 5.66927 6.07552 5.6875 5.65625C5.66927 5.23698 5.48698 4.91797 5.14062 4.69922C4.77604 4.51693 4.41146 4.51693 4.04688 4.69922C3.70052 4.91797 3.51823 5.23698 3.5 5.65625ZM7 5.65625C6.96354 6.56771 6.5625 7.26042 5.79688 7.73438C4.99479 8.17188 4.19271 8.17188 3.39062 7.73438C2.625 7.26042 2.22396 6.56771 2.1875 5.65625C2.22396 4.74479 2.625 4.05208 3.39062 3.57812C4.19271 3.14062 4.99479 3.14062 5.79688 3.57812C6.5625 4.05208 6.96354 4.74479 7 5.65625Z'></path>
    </svg>
  );
};
