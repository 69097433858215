export const HokenIcon = () => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_20646_84285)'>
        <path
          d='M12.7874 0H21.2581C21.6673 0 21.9986 0.331295 21.9986 0.740459V9.21115C21.9986 9.8709 21.2015 10.2008 20.7343 9.73499L12.2636 1.2643C11.7964 0.797091 12.1277 0 12.7874 0Z'
          fill='#5BFCB8'
        />
        <path
          d='M21.9986 12.7846V21.2609C21.9986 21.6687 21.6673 22 21.2596 22H12.7832C12.1249 22 11.795 21.2043 12.2608 20.7385L20.7371 12.2621C21.2029 11.7963 21.9986 12.1262 21.9986 12.7846Z'
          fill='#5BFCB8'
        />
        <path
          d='M9.21681 22H0.739044C0.331295 22 0 21.6687 0 21.261V12.7832C0 12.1249 0.795675 11.7964 1.26147 12.2608L9.73924 20.7385C10.205 21.2043 9.87515 22 9.21681 22Z'
          fill='#5BFCB8'
        />
        <path
          d='M0 9.21115V0.74046C0 0.331295 0.331295 0 0.74046 0H9.20973C9.86949 0 10.2008 0.798507 9.73357 1.2643L1.2643 9.73499C0.797091 10.2022 0 9.8709 0 9.21115Z'
          fill='#5BFCB8'
        />
      </g>
      <defs>
        <clipPath id='clip0_20646_84285'>
          <rect width='21.9986' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
