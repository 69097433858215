import React from "react";

export const EpicSavings = () => {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M98.6817 64.884L105.363 71.5364C106.719 72.8862 106.482 75.1429 104.876 76.1816L98.9923 79.9861C97.7998 80.7572 97.3113 82.2588 97.8219 83.584L100.329 90.0914C101.016 91.8734 99.8848 93.8349 97.9985 94.1332L91.0386 95.2338C89.6402 95.4549 88.5868 96.6235 88.5114 98.0372L88.139 105.018C88.0376 106.92 86.2131 108.247 84.3727 107.758L77.5392 105.942C76.1756 105.579 74.7438 106.216 74.0996 107.471L70.89 113.725C70.0224 115.416 67.8217 115.884 66.3409 114.693L60.8365 110.267C59.7386 109.384 58.1743 109.384 57.0764 110.267L51.572 114.693C50.0913 115.884 47.8905 115.416 47.0229 113.725L43.8134 107.471C43.1692 106.216 41.7373 105.579 40.3738 105.942L33.5402 107.758C31.6998 108.247 29.8753 106.92 29.7739 105.018L29.4015 98.0372C29.3261 96.6235 28.2727 95.4549 26.8744 95.2338L19.9145 94.1332C18.0281 93.8349 16.897 91.8734 17.5837 90.0914L20.9674 81.3096M11 73.0846L17.5035 66.6053C18.51 65.6026 18.6751 64.0313 17.8989 62.8412L14.0868 56.9962C13.0424 55.3948 13.7424 53.2381 15.5281 52.5552L22.0913 50.0454C23.4159 49.5388 24.2049 48.1739 23.9827 46.7732L22.8893 39.8809C22.5905 37.9973 24.102 36.3167 26.0065 36.4148L33.0591 36.7779C34.4704 36.8506 35.741 35.9285 36.1096 34.5643L37.9375 27.7978C38.4335 25.9618 40.4908 25.0447 42.1879 25.9032L48.4978 29.0952C49.7554 29.7314 51.2863 29.4064 52.177 28.3141L56.6315 22.8513C57.8319 21.3791 60.081 21.3791 61.2815 22.8513L65.7359 28.3141C66.6266 29.4064 68.1575 29.7314 69.4151 29.0952L75.7251 25.9032C77.4221 25.0447 79.4794 25.9618 79.9754 27.7978L81.8034 34.5643C82.1719 35.9285 83.4425 36.8506 84.8538 36.7779L91.9064 36.4148C93.811 36.3167 95.3224 37.9973 95.0236 39.8809L93.9879 46.4098C93.7408 47.9674 94.7419 49.4504 96.279 49.8036L101.663 51.041'
        stroke='#5BFCB8'
        strokeWidth='2'
      />
      <path
        d='M53 38.1745L57.1708 32.9426C58.3718 31.436 60.6614 31.436 61.8624 32.9426L64.4972 36.2477C65.39 37.3676 66.9498 37.7003 68.2217 37.0421L71.9318 35.1224C73.637 34.24 75.7207 35.1644 76.211 37.0208L77.3169 41.2082C77.6797 42.5819 78.9564 43.5127 80.3753 43.438L84.5845 43.2163C86.4861 43.1162 87.9987 44.7902 87.7068 46.6719L87.0293 51.0402C86.8139 52.4293 87.5919 53.7818 88.9011 54.2939L92.9193 55.8656C94.6793 56.5541 95.3721 58.6788 94.356 60.2724L91.9711 64.0127C91.2201 65.1906 91.3814 66.7308 92.3601 67.7275L95.4503 70.8745C96.772 72.2206 96.5382 74.438 94.9648 75.4787L91.3788 77.8505C90.1853 78.6399 89.7155 80.1624 90.2566 81.4871L92.9168 88'
        stroke='#5BFCB8'
      />
      <path
        d='M42 62.8719C42 58.4441 44.3351 56 49.2175 56C54.0999 56 56.435 58.4441 56.435 62.8719C56.435 67.2997 54.0999 69.7439 49.2175 69.7439C44.3351 69.7439 42 67.2997 42 62.8719ZM65.2799 56H68.7471L52.6847 82H49.2175L65.2799 56ZM49.1821 59.0463C46.8117 59.0463 46.1748 60.8174 46.1748 62.8719C46.1748 64.9619 46.8117 66.6975 49.2175 66.6975C51.6233 66.6975 52.2601 64.9973 52.2601 62.8719C52.2601 60.5341 51.4818 59.0463 49.1821 59.0463ZM61.565 75.1281C61.565 70.7003 63.9001 68.2561 68.7825 68.2561C73.6649 68.2561 76 70.7003 76 75.1281C76 79.5559 73.6649 82 68.7825 82C63.9001 82 61.565 79.5559 61.565 75.1281ZM68.7471 71.3025C66.3767 71.3025 65.7399 73.0736 65.7399 75.1281C65.7399 77.218 66.3767 78.9537 68.7825 78.9537C71.1883 78.9537 71.8252 77.2534 71.8252 75.1281C71.8252 72.7902 71.0468 71.3025 68.7471 71.3025Z'
        fill='#5BFCB8'
      />
      <path
        d='M82 100.384L74.4057 98.3243C73.0296 97.951 71.5809 98.598 70.9403 99.8718L69.0401 103.65C68.1764 105.368 65.9429 105.841 64.4568 104.621L59.3401 100.422M51.3248 107L47.7399 99.8718C47.0993 98.598 45.6506 97.951 44.2745 98.3243L40.2674 99.411C38.4222 99.9114 36.586 98.5814 36.4862 96.6722L36.2607 92.3576C36.1869 90.9462 35.138 89.7777 33.7427 89.5525L29.5799 88.8808C27.7065 88.5785 26.5809 86.6346 27.2512 84.8594L28.7949 80.7711C29.2902 79.4594 28.8109 77.9796 27.6406 77.2073L24.0444 74.8343C22.4675 73.7938 22.2334 71.5723 23.5586 70.2259L26.6068 67.129C27.5885 66.1317 27.7502 64.5881 26.9966 63.409L24.6448 59.7292C23.6258 58.1348 24.3193 56.0068 26.082 55.3189L30.0534 53.7691C31.3641 53.2576 32.1433 51.9038 31.9271 50.5135L31.2584 46.2116C30.9658 44.3297 32.4783 42.655 34.3802 42.755L38.5179 42.9723C39.9512 43.0477 41.2371 42.0972 41.5855 40.7048L43.5138 33'
        stroke='#5BFCB8'
      />
    </svg>
  );
};
