import React from "react";

export const Map = () => {
  return (
    <svg
      width='14'
      height='11'
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.5 2.25952C0.5 1.95483 0.6875 1.67358 0.96875 1.5564L4.25 0.243896V9.2439L0.992188 10.7205C0.757812 10.8376 0.5 10.6501 0.5 10.3689V2.25952ZM5 9.2439V0.243896L9.5 1.7439V10.7439L5 9.2439ZM13.4844 0.290771C13.7188 0.173584 14 0.361084 14 0.642334V8.75171C14 9.0564 13.7891 9.33765 13.5078 9.45483L10.25 10.7439V1.7439L13.4844 0.290771Z'
        fill='#A4A2BD'
      />
    </svg>
  );
};
