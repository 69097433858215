import React from "react";

import { IconProps } from "./IconProps";

export const Moon = ({ color, className }: IconProps) => {
  return (
    <svg
      width='10'
      height='11'
      viewBox='0 0 10 11'
      fill={color || "currentColor"}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 5.5C0 2.47991 2.50379 0 5.58346 0C5.88392 0 6.3346 0.0491071 6.61002 0.0982143C6.8604 0.147321 6.91047 0.466518 6.68513 0.589286C5.33308 1.35045 4.48179 2.77455 4.48179 4.32143C4.48179 7.02232 6.96055 9.06027 9.68968 8.5692C9.94006 8.52009 10.0903 8.79018 9.94006 8.98661C8.88847 10.2388 7.31108 11 5.58346 11C2.50379 11 0 8.54464 0 5.5Z' />
    </svg>
  );
};
