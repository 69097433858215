export const HokenLogoWhite = () => {
  return (
    <svg width={18} height={18} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)' fill='#000'>
        <path d='M10.463 0h6.931c.335 0 .606.271.606.606v6.93c0 .54-.652.81-1.035.43l-6.93-6.931A.606.606 0 0 1 10.462 0ZM18.002 10.46v6.936a.605.605 0 0 1-.605.605h-6.936a.604.604 0 0 1-.427-1.032l6.935-6.936a.605.605 0 0 1 1.033.427ZM7.542 18.001H.605A.605.605 0 0 1 0 17.397V10.46a.605.605 0 0 1 1.032-.428L7.97 16.97a.605.605 0 0 1-.427 1.032ZM0 7.537V.606C0 .27.271 0 .606 0h6.93c.54 0 .81.653.428 1.034l-6.93 6.931A.606.606 0 0 1 0 7.537Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h18v18.001H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
