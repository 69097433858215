import React from "react";

export const ChangeOfPlans = () => {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='61.5' cy='67.5' r='44.5' stroke='#5BFCB8' strokeWidth='2' />
      <path
        d='M43 83C43 83 47.573 91 61.5 91C75.427 91 80 83 80 83'
        stroke='#5BFCB8'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M68 50H56L59.6979 52.6667L59.1304 57H64.8696L63.8261 52.6667L68 50Z'
        fill='#5BFCB8'
      />
      <path
        d='M30.9631 51C28.3123 51 26.387 53.5383 27.1086 56.0916C28.5562 61.2143 29.8849 65.4684 32.2401 68.4087C34.6815 71.4565 38.1319 73 43.5 73C49.6308 73 53.6742 71.4962 56.165 68.2916C58.598 65.1613 59.3645 60.6207 59.5435 55.0361C59.6154 52.7946 57.7887 51 55.5881 51H30.9631Z'
        stroke='#5BFCB8'
        strokeWidth='2'
      />
      <path
        d='M44 67.5C50.0276 67.5 52.3502 65.2744 53.1617 61'
        stroke='#5BFCB8'
        strokeWidth='2'
      />
      <path d='M23 50H30.5L26 56C26 52.8 24 52.3333 23 52.5V50Z' fill='#5BFCB8' />
      <path
        d='M92.6892 51C95.3401 51 97.2653 53.5383 96.5438 56.0916C95.0961 61.2143 93.7674 65.4684 91.4123 68.4087C88.9709 71.4565 85.5204 73 80.1523 73C73.9557 73 69.9023 71.4983 67.4242 68.2891C65.0053 65.1567 64.2751 60.6132 64.1061 55.0344C64.0382 52.7947 65.8631 51 68.0642 51H92.6892Z'
        stroke='#5BFCB8'
        strokeWidth='2'
      />
      <path
        d='M100.652 50H93.1523L97.6523 56C97.6523 52.8 99.6523 52.3333 100.652 52.5V50Z'
        fill='#5BFCB8'
      />
      <path
        d='M81 67.5C87.0276 67.5 89.3502 65.2744 90.1617 61'
        stroke='#5BFCB8'
        strokeWidth='2'
      />
      <path
        d='M99.6431 30.458C99.5347 30.8973 98.9102 30.8973 98.8017 30.458L97.8553 26.6242C97.8167 26.4679 97.6947 26.3459 97.5384 26.3074L93.7046 25.3609C93.2653 25.2525 93.2653 24.628 93.7046 24.5195L97.5384 23.5731C97.6947 23.5345 97.8167 23.4125 97.8553 23.2563L98.8017 19.4224C98.9102 18.9831 99.5347 18.9831 99.6431 19.4224L100.59 23.2563C100.628 23.4125 100.75 23.5345 100.906 23.5731L104.74 24.5195C105.18 24.628 105.18 25.2525 104.74 25.3609L100.906 26.3074C100.75 26.3459 100.628 26.4679 100.59 26.6242L99.6431 30.458Z'
        fill='#5BFCB8'
      />
      <path
        d='M108.344 32.6396C108.235 33.0788 107.611 33.0788 107.503 32.6396L107.271 31.7017C107.232 31.5455 107.11 31.4235 106.954 31.3849L106.016 31.1534C105.577 31.0449 105.577 30.4205 106.016 30.312L106.954 30.0805C107.11 30.0419 107.232 29.9199 107.271 29.7637L107.503 28.8258C107.611 28.3866 108.235 28.3866 108.344 28.8258L108.575 29.7637C108.614 29.9199 108.736 30.0419 108.892 30.0805L109.83 30.312C110.269 30.4205 110.269 31.0449 109.83 31.1534L108.892 31.3849C108.736 31.4235 108.614 31.5455 108.575 31.7017L108.344 32.6396Z'
        fill='#5BFCB8'
      />
      <path
        d='M109.514 23.54C109.406 23.9793 108.782 23.9793 108.673 23.54L108.241 21.7913C108.203 21.635 108.081 21.513 107.925 21.4745L106.176 21.0428C105.737 20.9343 105.737 20.3098 106.176 20.2014L107.925 19.7697C108.081 19.7311 108.203 19.6091 108.241 19.4529L108.673 17.7041C108.782 17.2649 109.406 17.2649 109.514 17.7041L109.946 19.4529C109.985 19.6091 110.107 19.7311 110.263 19.7697L112.012 20.2014C112.451 20.3098 112.451 20.9343 112.012 21.0428L110.263 21.4745C110.107 21.513 109.985 21.635 109.946 21.7913L109.514 23.54Z'
        fill='#5BFCB8'
      />
    </svg>
  );
};
