import React from "react";

export const HokenSavings = () => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.7335 16.7217C25.8739 15.5813 27.3731 14.8697 28.9777 14.7071L41.7229 13.4158C43.6119 13.2244 45.2014 14.8139 45.01 16.7029L43.7187 29.4481C43.5561 31.0527 42.8445 32.5519 41.7041 33.6923L23.2131 52.1832C21.651 53.7453 19.1184 53.7453 17.5563 52.1832L6.24257 40.8695C4.68047 39.3074 4.68047 36.7748 6.24257 35.2127L24.7335 16.7217Z'
        stroke='#5BFCB8'
        stroke-width='2'
      />
      <circle cx='19.499' cy='32.5' r='2.5' stroke='#5BFCB8' stroke-width='2' />
      <circle cx='32.499' cy='32.5' r='2.5' stroke='#5BFCB8' stroke-width='2' />
      <line x1='25.999' y1='43' x2='25.999' y2='23' stroke='#5BFCB8' stroke-width='2' />
      <path
        d='M26.2491 11.4319C26.5708 11.9645 25.9635 12.5718 25.4309 12.25L23.6391 11.1678C23.4497 11.0533 23.2124 11.0533 23.023 11.1678L21.2312 12.25C20.6986 12.5718 20.0913 11.9645 20.413 11.4319L21.4953 9.64012C21.6097 9.45067 21.6097 9.2134 21.4953 9.02394L20.413 7.23217C20.0913 6.69956 20.6986 6.09231 21.2312 6.41401L23.023 7.49628C23.2124 7.61072 23.4497 7.61072 23.6391 7.49628L25.4309 6.41401C25.9635 6.09231 26.5708 6.69956 26.2491 7.23217L25.1668 9.02394C25.0524 9.2134 25.0524 9.45067 25.1668 9.64012L26.2491 11.4319Z'
        fill='#5BFCB8'
      />
      <path
        d='M39.9251 20.0562C40.5292 20.2053 40.5292 21.0641 39.9251 21.2132L38.9009 21.4661C38.686 21.5191 38.5183 21.6869 38.4652 21.9018L38.2124 22.9259C38.0632 23.53 37.2045 23.53 37.0553 22.9259L36.8025 21.9018C36.7494 21.6869 36.5817 21.5191 36.3668 21.4661L35.3426 21.2132C34.7385 21.0641 34.7385 20.2053 35.3426 20.0562L36.3668 19.8033C36.5817 19.7503 36.7494 19.5825 36.8025 19.3676L37.0553 18.3435C37.2045 17.7394 38.0632 17.7394 38.2124 18.3435L38.4652 19.3676C38.5183 19.5825 38.686 19.7503 38.9009 19.8033L39.9251 20.0562Z'
        fill='#5BFCB8'
      />
      <path
        d='M47.1888 9.46699C47.473 9.93751 46.9365 10.474 46.466 10.1898C46.2986 10.0887 46.089 10.0887 45.9216 10.1898C45.4511 10.474 44.9147 9.93751 45.1989 9.46699C45.3 9.29963 45.3 9.09002 45.1989 8.92265C44.9147 8.45214 45.4511 7.91569 45.9216 8.19989C46.089 8.30098 46.2986 8.30098 46.466 8.19989C46.9365 7.91569 47.473 8.45214 47.1888 8.92266C47.0877 9.09002 47.0877 9.29963 47.1888 9.46699Z'
        fill='#5BFCB8'
      />
      <path
        d='M37.8614 8.45514C38.3951 8.58688 38.3951 9.34554 37.8614 9.47728C37.6716 9.52414 37.5234 9.67236 37.4765 9.86219C37.3448 10.3959 36.5861 10.3959 36.4544 9.86219C36.4075 9.67236 36.2593 9.52414 36.0695 9.47728C35.5358 9.34554 35.5358 8.58688 36.0695 8.45514C36.2593 8.40828 36.4075 8.26006 36.4544 8.07023C36.5861 7.53657 37.3448 7.53657 37.4765 8.07023C37.5234 8.26006 37.6716 8.40828 37.8614 8.45514Z'
        fill='#5BFCB8'
      />
      <path
        d='M50.0538 20.49C50.2758 20.8576 49.8567 21.2767 49.4891 21.0546C49.3584 20.9757 49.1946 20.9757 49.0638 21.0546C48.6963 21.2767 48.2772 20.8576 48.4992 20.49C48.5782 20.3592 48.5782 20.1955 48.4992 20.0647C48.2772 19.6971 48.6963 19.278 49.0638 19.5001C49.1946 19.579 49.3584 19.579 49.4891 19.5001C49.8567 19.278 50.2758 19.6971 50.0538 20.0647C49.9748 20.1955 49.9748 20.3592 50.0538 20.49Z'
        fill='#5BFCB8'
      />
    </svg>
  );
};
