import React from "react";

import { IconProps } from "./IconProps";

export const UserSolid = ({ color, className }: IconProps) => {
  return (
    <svg
      width='10'
      height='11'
      viewBox='0 0 10 11'
      fill={color || "currentColor"}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M2 3C2 4.66406 3.33594 6 5 6C6.64063 6 8 4.66406 8 3C8 1.35937 6.64063 0 5 0C3.33594 0 2 1.35937 2 3Z' />
      <path d='M10 10.3461C10 8.5 8.25893 7 6.11607 7H3.86161C1.71875 7 0 8.5 0 10.3461C0 10.7115 0.334822 11 0.75893 11H9.21875C9.64286 11 10 10.7115 10 10.3461Z' />
    </svg>
  );
};
