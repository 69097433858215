import React from "react";

import { IconProps } from "./IconProps";

export const Repeat = ({ color, className }: IconProps) => {
  return (
    <svg
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill={color || "#4033D0"}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M10.3125 5.5073C10.6777 5.5073 11 5.82956 11 6.1948C11 8.1069 9.45312 9.6323 7.5625 9.6323H4.125V10.4917C4.125 10.7065 3.99609 10.8999 3.80273 10.9643C3.73828 11.0073 3.67383 11.0073 3.60938 11.0073C3.48047 11.0073 3.35156 10.9643 3.24414 10.8784L1.52539 9.33151C1.41797 9.24558 1.375 9.09519 1.375 8.9448C1.375 8.81589 1.41797 8.6655 1.52539 8.57956L3.24414 7.03269C3.41602 6.8823 3.63086 6.86081 3.80273 6.94675C3.99609 7.0112 4.125 7.20456 4.125 7.39792V8.2573H7.5625C8.67969 8.2573 9.625 7.33347 9.625 6.1948C9.625 5.82956 9.92578 5.5073 10.3125 5.5073ZM3.4375 2.7573C2.29883 2.7573 1.375 3.70261 1.375 4.8198C1.375 5.20651 1.05273 5.5073 0.6875 5.5073C0.300781 5.5073 0 5.20651 0 4.8198C0 2.92917 1.52539 1.3823 3.4375 1.3823H6.875L6.85352 0.52292C6.85352 0.329561 6.98242 0.136201 7.17578 0.050264C7.34766 -0.0356735 7.58398 -0.0141892 7.73438 0.136201L9.45312 1.68308C9.56055 1.76901 9.625 1.89792 9.625 2.0698C9.625 2.22019 9.56055 2.37058 9.43164 2.45651L7.71289 4.00339C7.60547 4.08933 7.47656 4.1323 7.36914 4.1323C7.30469 4.1323 7.24023 4.1323 7.17578 4.08933C6.98242 4.02487 6.875 3.83151 6.875 3.61667L6.85352 2.7573H3.4375Z' />
    </svg>
  );
};
