import React from "react";

export const FireHoken = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='13'
      fill='none'
      viewBox='0 0 12 13'
    >
      <path
        fill='url(#paint0_linear_20638_42706)'
        d='M8.201 1.32c1.854 1.701 3.174 4.494 3.174 5.84 0 3.225-2.564 5.84-5.688 5.84C2.54 13 0 10.385 0 7.16 0 5.332 1.752 2.336 4.266 0c.964.914 1.828 1.879 2.513 2.844.432-.533.889-1.041 1.422-1.524zM7.72 9.953c1.244-.863 1.523-2.564.838-3.885-.077-.152-.178-.33-.28-.507L6.78 7.26S4.443 4.267 4.266 4.063C3.046 5.562 2.437 6.425 2.437 7.39c0 1.98 1.473 3.173 3.301 3.173.737 0 1.422-.228 1.98-.609z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_20638_42706'
          x1='6.5'
          x2='17.798'
          y1='-4'
          y2='5.241'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5BFCB8'></stop>
          <stop offset='1' stopColor='#4033D0'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
