import React from "react";

export const Close = ({ fill = "#fff", width = 12, height = 18 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 12 18'
    >
      <g>
        <path
          fill={fill}
          d='M12 4.209L10.791 3 6 7.791 1.209 3 0 4.209 4.791 9 0 13.791 1.209 15 6 10.209 10.791 15 12 13.791 7.209 9 12 4.209z'
        ></path>
      </g>
    </svg>
  );
};
