import React from "react";

export const ShadowMesh = () => {
  return (
    <svg
      width='88'
      height='182'
      viewBox='0 0 88 182'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      // xmlns:xlink='http://www.w3.org/1999/xlink'
    >
      <rect
        x='0.0493164'
        width='87.9506'
        height='182'
        fill='url(#pattern0)'
        fillOpacity='0.2'
      />
      <defs>
        <pattern
          id='pattern0'
          patternContentUnits='objectBoundingBox'
          width='0.163728'
          height='0.0791209'
        >
          <use xlinkHref='#image0_26339_18539' transform='scale(0.00341101 0.00164835)' />
        </pattern>
        <image
          id='image0_26339_18539'
          width='48'
          height='48'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACYSURBVHgB7dixCcQwEAXRKeX339SV4rPBTh1J/izMghBKhocCBYK1k3P9znXce1g72dkP4jv9IL7TD+I7/SC+0w/iO/0gvtMP4jv9IL7TD+I7/TAYD7Pxgdn4qzsafzyHXfEvLmdrPKyd4GtWiCO+FEd8KY74UhzxpTjiS3HEl+KIL8URX4oj/n0m4wOz8VfXf6G3+PbL+QNK+h7w+XQxcQAAAABJRU5ErkJggg=='
        />
      </defs>
    </svg>
  );
};
