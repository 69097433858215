import React from "react";

export const HokenRefunds = () => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 28.5C8 17.1782 17.1782 8 28.5 8C38.2759 8 46.4536 14.8428 48.5045 24'
        stroke='#5BFCB8'
        stroke-width='2'
      />
      <path
        d='M49 28.5C49 39.8218 39.8218 49 28.5 49C18.7241 49 10.5464 42.1572 8.49546 33'
        stroke='#5BFCB8'
        stroke-width='2'
      />
      <path
        d='M4 25L8 29L12 25'
        stroke='#5BFCB8'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M53 32L49 28L45 32'
        stroke='#5BFCB8'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M23 37V37C24.3153 37.6576 25.7656 38 27.2361 38H28.0427C30.7805 38 33 35.7805 33 33.0427V32.62C33 30.5649 31.4428 28.8443 29.398 28.6398L28 28.5L26.3429 28.3343C24.4452 28.1445 23 26.5476 23 24.6404V24.3377C23 21.942 24.942 20 27.3377 20H27.7639C29.2344 20 30.6847 20.3424 32 21V21'
        stroke='#5BFCB8'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path d='M28 20L28 17' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M28 41L28 38' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path
        d='M42.9261 23.0562C43.5302 23.2053 43.5302 24.0641 42.9261 24.2132L41.9019 24.4661C41.687 24.5191 41.5192 24.6869 41.4662 24.9018L41.2134 25.9259C41.0642 26.53 40.2054 26.53 40.0563 25.9259L39.8035 24.9018C39.7504 24.6869 39.5826 24.5191 39.3678 24.4661L38.3436 24.2132C37.7395 24.0641 37.7395 23.2053 38.3436 23.0562L39.3678 22.8033C39.5826 22.7503 39.7504 22.5825 39.8035 22.3676L40.0563 21.3435C40.2054 20.7394 41.0642 20.7394 41.2134 21.3435L41.4662 22.3676C41.5192 22.5825 41.687 22.7503 41.9019 22.8033L42.9261 23.0562Z'
        fill='#5BFCB8'
      />
      <path
        d='M18.1897 32.467C18.4739 32.9375 17.9375 33.474 17.467 33.1898C17.2996 33.0887 17.09 33.0887 16.9226 33.1898C16.4521 33.474 15.9157 32.9375 16.1999 32.467C16.301 32.2996 16.301 32.09 16.1999 31.9227C15.9157 31.4521 16.4521 30.9157 16.9226 31.1999C17.09 31.301 17.2996 31.301 17.467 31.1999C17.9375 30.9157 18.4739 31.4521 18.1897 31.9227C18.0886 32.09 18.0886 32.2996 18.1897 32.467Z'
        fill='#5BFCB8'
      />
      <path
        d='M47.0548 7.48998C47.2768 7.85757 46.8577 8.27667 46.4901 8.05464C46.3593 7.97566 46.1956 7.97566 46.0648 8.05464C45.6972 8.27667 45.2781 7.85757 45.5002 7.48998C45.5791 7.35922 45.5791 7.19547 45.5002 7.06471C45.2781 6.69712 45.6972 6.27802 46.0648 6.50005C46.1956 6.57903 46.3593 6.57903 46.4901 6.50005C46.8577 6.27802 47.2768 6.69712 47.0548 7.06471C46.9758 7.19547 46.9758 7.35922 47.0548 7.48998Z'
        fill='#5BFCB8'
      />
    </svg>
  );
};
