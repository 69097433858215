import React from "react";

export const ExclamationTriangle = () => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.4536 11.8221C14.9106 12.6346 14.3266 13.6248 13.3872 13.6248H1.22507C0.285614 13.6248 -0.298371 12.6092 0.158661 11.8221L6.25241 1.23417C6.70944 0.421669 7.9028 0.447059 8.35983 1.23417L14.4536 11.8221ZM7.31882 9.61308C6.65866 9.61308 6.15085 10.1463 6.15085 10.781C6.15085 11.4412 6.65866 11.949 7.31882 11.949C7.95358 11.949 8.48679 11.4412 8.48679 10.781C8.48679 10.1463 7.95358 9.61308 7.31882 9.61308ZM6.20163 5.42362L6.37936 8.87675C6.40475 9.05448 6.53171 9.15604 6.68405 9.15604H7.92819C8.08054 9.15604 8.20749 9.05448 8.23288 8.87675L8.41061 5.42362C8.436 5.24589 8.28366 5.09354 8.10593 5.09354H6.50632C6.32858 5.09354 6.17624 5.24589 6.20163 5.42362Z'
        fill='#FFA800'
      />
    </svg>
  );
};
