import React from "react";

import { IconProps } from "./IconProps";

export const CheckmarkPurple = ({ color, className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='11'
      fill={color || "#4033D0"}
      viewBox='0 0 14 11'
      className={className}
    >
      <path d='M4.744 10.24L.205 5.701a.726.726 0 010-1.012l.984-.984a.693.693 0 01.985 0l3.09 3.063L11.826.205a.693.693 0 01.984 0l.985.984a.726.726 0 010 1.012l-8.04 8.04a.726.726 0 01-1.01 0z'></path>
    </svg>
  );
};

export const CheckmarkSuccess = ({ color, className }: IconProps) => {
  return (
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill={color || "#00A538"}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M11.8125 6.5C11.8125 9.71094 9.1875 12.3125 6 12.3125C2.78906 12.3125 0.1875 9.71094 0.1875 6.5C0.1875 3.3125 2.78906 0.6875 6 0.6875C9.1875 0.6875 11.8125 3.3125 11.8125 6.5ZM5.32031 9.59375L9.63281 5.28125C9.77344 5.14062 9.77344 4.88281 9.63281 4.74219L9.09375 4.22656C8.95312 4.0625 8.71875 4.0625 8.57812 4.22656L5.0625 7.74219L3.39844 6.10156C3.25781 5.9375 3.02344 5.9375 2.88281 6.10156L2.34375 6.61719C2.20312 6.75781 2.20312 7.01562 2.34375 7.15625L4.78125 9.59375C4.92188 9.73438 5.17969 9.73438 5.32031 9.59375Z' />
    </svg>
  );
};
