import React from "react";

import { IconProps } from "./IconProps";

export const Users2 = ({ color, className }: IconProps) => {
  return (
    <svg
      width='25'
      className={className}
      height='12'
      viewBox='0 0 25 12'
      fill={color || "currentColor"}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M2.4375 3.125C2.4375 4.65039 3.66211 5.875 5.1875 5.875C6.69141 5.875 7.9375 4.65039 7.9375 3.125C7.9375 1.62109 6.69141 0.375 5.1875 0.375C3.66211 0.375 2.4375 1.62109 2.4375 3.125Z' />
      <path d='M10 10.6445C10 8.58203 8.32422 6.90625 6.26172 6.90625H4.0918C2.0293 6.90625 0.375 8.58203 0.375 10.6445C0.375 11.0527 0.697266 11.375 1.10547 11.375H9.24805C9.65625 11.375 10 11.0527 10 10.6445Z' />
      <path d='M17.4375 3.125C17.4375 4.65039 18.6621 5.875 20.1875 5.875C21.6914 5.875 22.9375 4.65039 22.9375 3.125C22.9375 1.62109 21.6914 0.375 20.1875 0.375C18.6621 0.375 17.4375 1.62109 17.4375 3.125Z' />
      <path d='M25 10.6445C25 8.58203 23.3242 6.90625 21.2617 6.90625H19.0918C17.0293 6.90625 15.375 8.58203 15.375 10.6445C15.375 11.0527 15.6973 11.375 16.1055 11.375H24.248C24.6562 11.375 25 11.0527 25 10.6445Z' />
    </svg>
  );
};
