// Stripe related icons
import { AmexLogo } from "./amex-logo";
import { BackArrow } from "./back-arrow";
import { Bed } from "./bed";
import { BedAlt } from "./bed-alt";
import { ChangeOfPlans } from "./change-of-plans";
import { Chart } from "./chart";
import { CheckmarkPurple, CheckmarkSuccess } from "./checkmark";
import { ChevronDown } from "./chevron-down";
import { ChevronLeft } from "./chevron-left";
import { CircleCheck } from "./circle-check";
import { CircleInfo } from "./circle-info";
import { Clock } from "./clock";
import { Close } from "./close";
import { DinersLogo } from "./diners-logo";
import { EndlessScrolling } from "./endless-scrolling";
import { Envelope } from "./envelope";
import { EpicSavings } from "./epic-savings";
import { ExclamationTriangle } from "./exclamation-traingle";
import { Fire } from "./fire";
import { FireHoken } from "./fire-hoken";
import { GoogleLogo } from "./google-logo";
import { HokenExpertTeam } from "./hoken-expert-team";
import { HokenIcon } from "./hoken-icon";
import { HokenLocations } from "./hoken-locations";
import { HokenLocationsAndSavings } from "./hoken-locations-and-savings";
import { HokenLocationsMini } from "./hoken-locations-mini";
import { HokenLogoWhite } from "./hoken-logo-white";
import { HokenRefunds } from "./hoken-refunds";
import { HokenRefundsMini } from "./hoken-refunds-mini";
import { HokenSavings } from "./hoken-savings";
import { HokenSavingsMini } from "./hoken-savings-mini";
import { Hotel } from "./hotel";
import { IndigoChartLine } from "./indigo-chart-line";
import { Map } from "./map";
import { MapMarker } from "./map-marker";
import { MastercardLogo } from "./mastercard-logo";
import { Moon } from "./moon";
import { OpenLink } from "./open-link";
import { PartialBBG } from "./partial-bbg";
import { PartyPopper } from "./party-popper";
import { Repeat } from "./repeat";
import { Search } from "./search";
import { ShadowMesh } from "./shadow-mesh";
import { SingleBed } from "./single-bed";
import { Star } from "./star";
import { User } from "./user";
import { UserCheck } from "./user-check";
import { UserError } from "./user-error";
import { UserSolid } from "./user-solid";
import { Users2 } from "./users-2";
import { Users2Plus } from "./users-2-plus";
import { VipAccess } from "./vip-access";
import { VisaLogo } from "./visa-logo";
import { XLogo } from "./x-logo";

interface IconProps {
  color?: string;
  className?: string;
  [key: string]: any;
}

export const createIconWrapper = (
  IconComponent: React.ComponentType<any>,
  supportsProps = false,
) => {
  const IconWrapper = ({ color, className, ...props }: IconProps) => {
    // Apply color and className only if the icon supports them
    const passedProps = supportsProps ? { ...props, color, className } : props;
    return <IconComponent {...passedProps} />;
  };

  // Adding displayName to the wrapper component
  IconWrapper.displayName = `IconWrapper(${
    IconComponent.displayName || IconComponent.name || "Component"
  })`;

  return IconWrapper;
};

export const Icons = () => {
  return null;
};

// If you add a new icon and it accepts color and className props, remember to pass the supportsProps flag as true
// Check ChevronDown for an example of how to make icons accept this two props.
Icons.AmexLogo = createIconWrapper(AmexLogo);
Icons.Bed = createIconWrapper(Bed);
Icons.BedAlt = createIconWrapper(BedAlt, true);
Icons.BackArrow = createIconWrapper(BackArrow, true);
Icons.ChangeOfPlans = createIconWrapper(ChangeOfPlans);
Icons.Chart = createIconWrapper(Chart);
Icons.CheckmarkPurple = createIconWrapper(CheckmarkPurple, true);
Icons.CheckmarkSuccess = createIconWrapper(CheckmarkSuccess);
Icons.ChevronDown = createIconWrapper(ChevronDown, true);
Icons.ChevronLeft = createIconWrapper(ChevronLeft);
Icons.CircleCheck = createIconWrapper(CircleCheck);
Icons.CircleInfo = createIconWrapper(CircleInfo, true);
Icons.Clock = createIconWrapper(Clock);
Icons.Close = createIconWrapper(Close);
Icons.DinersLogo = createIconWrapper(DinersLogo);
Icons.EndlessScrolling = createIconWrapper(EndlessScrolling);
Icons.Envelope = createIconWrapper(Envelope);
Icons.EpicSavings = createIconWrapper(EpicSavings);
Icons.ExclamationTriangle = createIconWrapper(ExclamationTriangle);
Icons.Fire = createIconWrapper(Fire, true);
Icons.FireHoken = createIconWrapper(FireHoken);
Icons.GoogleLogo = createIconWrapper(GoogleLogo);
Icons.HokenExpertTeam = createIconWrapper(HokenExpertTeam);
Icons.HokenIcon = createIconWrapper(HokenIcon);
Icons.HokenLocations = createIconWrapper(HokenLocations);
Icons.HokenLocationsAndSavings = createIconWrapper(HokenLocationsAndSavings);
Icons.HokenLocationsMini = createIconWrapper(HokenLocationsMini);
Icons.HokenRefunds = createIconWrapper(HokenRefunds);
Icons.HokenRefundsMini = createIconWrapper(HokenRefundsMini);
Icons.HokenSavings = createIconWrapper(HokenSavings);
Icons.HokenSavingsMini = createIconWrapper(HokenSavingsMini, true);
Icons.Hotel = createIconWrapper(Hotel, true);
Icons.IndigoChartLine = createIconWrapper(IndigoChartLine);
Icons.MapMarker = createIconWrapper(MapMarker, true);
Icons.MastercardLogo = createIconWrapper(MastercardLogo);
Icons.Moon = createIconWrapper(Moon, true);
Icons.OpenLink = createIconWrapper(OpenLink);
Icons.PartialBBG = createIconWrapper(PartialBBG);
Icons.Repeat = createIconWrapper(Repeat, true);
Icons.Search = createIconWrapper(Search, true);
Icons.ShadowMesh = createIconWrapper(ShadowMesh);
Icons.Star = createIconWrapper(Star, true);
Icons.User = createIconWrapper(User, true);
Icons.UserSolid = createIconWrapper(UserSolid, true);
Icons.UserCheck = createIconWrapper(UserCheck);
Icons.Users2 = createIconWrapper(Users2, true);
Icons.Users2Plus = createIconWrapper(Users2Plus, true);
Icons.UserError = createIconWrapper(UserError);
Icons.VipAccess = createIconWrapper(VipAccess);
Icons.VisaLogo = createIconWrapper(VisaLogo);
Icons.HokenLogoWhite = createIconWrapper(HokenLogoWhite);
Icons.XLogo = createIconWrapper(XLogo);
Icons.SingleBed = createIconWrapper(SingleBed);
Icons.PartyPopper = createIconWrapper(PartyPopper);
Icons.Map = createIconWrapper(Map);
