import React from "react";

import { IconProps } from "./IconProps";

export const Users2Plus = ({ color, className }: IconProps) => {
  return (
    <svg
      className={className}
      width='37'
      height='12'
      viewBox='0 0 37 12'
      fill={color || "currentColor"}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M2.0625 3.25C2.0625 4.77539 3.28711 6 4.8125 6C6.31641 6 7.5625 4.77539 7.5625 3.25C7.5625 1.74609 6.31641 0.5 4.8125 0.5C3.28711 0.5 2.0625 1.74609 2.0625 3.25Z' />
      <path d='M9.625 10.7695C9.625 8.70703 7.94922 7.03125 5.88672 7.03125H3.7168C1.6543 7.03125 0 8.70703 0 10.7695C0 11.1777 0.322266 11.5 0.730469 11.5H8.87305C9.28125 11.5 9.625 11.1777 9.625 10.7695Z' />
      <path d='M18.4375 6C16.9121 6 15.6875 4.77539 15.6875 3.25C15.6875 1.74609 16.9121 0.5 18.4375 0.5C19.9414 0.5 21.1875 1.74609 21.1875 3.25C21.1875 4.77539 19.9414 6 18.4375 6Z' />
      <path d='M19.5117 7.03125C21.5742 7.03125 23.25 8.70703 23.25 10.7695C23.25 11.1777 22.9062 11.5 22.498 11.5H14.3555C13.9473 11.5 13.625 11.1777 13.625 10.7695C13.625 8.70703 15.2793 7.03125 17.3418 7.03125H19.5117Z' />
      <path d='M36.1875 6C36.1875 6.38672 35.8652 6.70898 35.5 6.70898H32.4062V9.80273C32.4062 10.168 32.084 10.4688 31.7188 10.4688C31.332 10.4688 31.0312 10.168 31.0312 9.80273V6.70898H27.9375C27.5508 6.70898 27.25 6.38672 27.25 6C27.25 5.63477 27.5508 5.33398 27.9375 5.33398H31.0312V2.24023C31.0312 1.85352 31.332 1.53125 31.7188 1.53125C32.084 1.53125 32.4062 1.85352 32.4062 2.24023V5.33398H35.5C35.8652 5.3125 36.1875 5.63477 36.1875 6Z' />
    </svg>
  );
};
