export const SingleBed = () => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame 4899'>
        <path
          id='&#239;&#136;&#182;'
          d='M2.5 4.0625V4.75V4.0625V4.75V10.25H7.3125V6.8125V6.125H8H12.8125C13.3997 6.13932 13.8867 6.33984 14.2734 6.72656C14.6602 7.11328 14.8607 7.60026 14.875 8.1875V13V13.6875H13.5V13V12.3125H8.6875H2.5V13V13.6875H1.125V13V4.75V4.0625H2.5ZM4.90625 6.125C5.55078 6.13932 6.04492 6.42578 6.38867 6.98438C6.70378 7.55729 6.70378 8.13021 6.38867 8.70312C6.04492 9.26172 5.55078 9.54818 4.90625 9.5625C4.26172 9.54818 3.76758 9.26172 3.42383 8.70312C3.10872 8.13021 3.10872 7.55729 3.42383 6.98438C3.76758 6.42578 4.26172 6.13932 4.90625 6.125Z'
          fill='#4033D0'
        />
      </g>
    </svg>
  );
};
