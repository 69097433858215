import React from "react";

import { IconProps } from "./IconProps";

export const Fire = ({ color, className }: IconProps) => {
  return (
    <svg
      width='13'
      height='15'
      viewBox='0 0 13 15'
      fill={color || "currentColor"}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M8.83203 1.92188C10.8281 3.75391 12.25 6.76172 12.25 8.21094C12.25 11.6836 9.48828 14.5 6.125 14.5C2.73438 14.5 0 11.6836 0 8.21094C0 6.24219 1.88672 3.01562 4.59375 0.5C5.63281 1.48438 6.5625 2.52344 7.30078 3.5625C7.76562 2.98828 8.25781 2.44141 8.83203 1.92188ZM8.3125 11.2188C9.65234 10.2891 9.95312 8.45703 9.21484 7.03516C9.13281 6.87109 9.02344 6.67969 8.91406 6.48828L7.30078 8.32031C7.30078 8.32031 4.78516 5.09375 4.59375 4.875C3.28125 6.48828 2.625 7.41797 2.625 8.45703C2.625 10.5898 4.21094 11.875 6.17969 11.875C6.97266 11.875 7.71094 11.6289 8.3125 11.2188Z'></path>
    </svg>
  );
};
