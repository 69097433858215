import React from "react";

export const HokenRefundsMini = () => {
  return (
    <svg
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.7852 0.65625C16.3828 0.0585938 17.4375 0.480469 17.4375 1.35938V6.45703C17.4375 6.84375 17.1211 7.125 16.7695 7.125H11.6016C10.7578 7.125 10.3711 6.10547 10.9336 5.50781L12.5156 3.96094C11.5664 3.15234 10.3008 2.625 9 2.625C6.64453 2.625 4.57031 4.13672 3.86719 6.35156C3.69141 6.94922 3.05859 7.26562 2.46094 7.08984C1.86328 6.91406 1.54688 6.28125 1.72266 5.68359C2.74219 2.51953 5.66016 0.375 9 0.375C10.9336 0.375 12.7266 1.14844 14.0977 2.37891L15.7852 0.65625ZM15.5039 9.44531C16.1016 9.62109 16.418 10.2539 16.207 10.8516C15.1875 14.0156 12.2695 16.125 8.96484 16.125C7.03125 16.125 5.23828 15.3867 3.86719 14.1562L2.17969 15.8789C1.58203 16.4766 0.5625 16.0547 0.5625 15.1758V10.0781C0.5625 9.69141 0.84375 9.41016 1.19531 9.375H6.36328C7.20703 9.41016 7.59375 10.4297 7.03125 10.9922L5.44922 12.5742C6.43359 13.3828 7.66406 13.875 9 13.875C11.3203 13.875 13.3945 12.3984 14.0977 10.1836C14.2734 9.58594 14.9062 9.26953 15.5039 9.44531Z'
        fill='#5BFCB8'
      />
    </svg>
  );
};
