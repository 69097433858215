import React from "react";

export const UserCheck = ({ fill = "#E88F3E" }) => {
  return (
    <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
      <path
        fill={fill}
        d='M96 128a128 128 0 1 1 256 0 128 128 0 1 1-256 0zM0 482.3C0 383.8 79.8 304 178.3 304h91.4c98.5 0 178.3 79.8 178.3 178.3 0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177 497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z'
      />
    </svg>
  );
};
