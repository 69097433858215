import React from "react";

export const Bed = () => {
  return (
    <svg
      width='23'
      height='22'
      viewBox='0 0 23 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 7C1 4.79086 2.79086 3 5 3H17C19.2091 3 21 4.79086 21 7V16H1V7Z'
        stroke='#4033D0'
        strokeWidth='2'
      />
      <path
        d='M4 8C4 6.89543 4.89543 6 6 6H16C17.1046 6 18 6.89543 18 8V14H4V8Z'
        fill='white'
        stroke='#4033D0'
        strokeWidth='2'
      />
      <path
        d='M1 14C1 11.7909 2.79086 10 5 10H17C19.2091 10 21 11.7909 21 14V17H1V14Z'
        fill='white'
        stroke='#4033D0'
        strokeWidth='2'
      />
      <line x1='3' y1='17' x2='3' y2='21' stroke='#4033D0' strokeWidth='2' />
      <line x1='19' y1='17' x2='19' y2='21' stroke='#4033D0' strokeWidth='2' />
      <line x1='11' y1='9' x2='11' y2='5' stroke='#4033D0' strokeWidth='2' />
    </svg>
  );
};
