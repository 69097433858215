import React from "react";

import { IconProps } from "./IconProps";

export const Search = ({ color, className }: IconProps) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill={color || "currentColor"}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M11.7362 10.3986C12.0878 10.7736 12.0878 11.3595 11.7362 11.7345C11.3612 12.0861 10.7753 12.0861 10.4003 11.7345L7.61123 8.92202C6.65029 9.55484 5.47842 9.88296 4.21279 9.7189C2.05654 9.41421 0.322169 7.6564 0.0409189 5.52358C-0.357519 2.33608 2.33779 -0.359228 5.52529 0.0392099C7.65811 0.32046 9.41592 2.05483 9.72061 4.21108C9.88467 5.47671 9.55654 6.64858 8.92373 7.58608L11.7362 10.3986ZM1.86904 4.86733C1.86904 6.5314 3.20498 7.86733 4.86904 7.86733C6.50967 7.86733 7.86904 6.5314 7.86904 4.86733C7.86904 3.22671 6.50967 1.86733 4.86904 1.86733C3.20498 1.86733 1.86904 3.22671 1.86904 4.86733Z'></path>
    </svg>
  );
};
