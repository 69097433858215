import React from "react";

export const IndigoChartLine = () => {
  return (
    <svg
      width='15'
      height='5'
      viewBox='0 0 15 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line y1='2.5' x2='15' y2='2.5' stroke='#4033D0' />
      <circle cx='7.5' cy='2.5' r='2' fill='white' stroke='#4033D0' />
    </svg>
  );
};
