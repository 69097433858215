import React from "react";

export const ChevronLeft = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='5'
      height='8'
      fill='none'
      viewBox='0 0 5 8'
    >
      <path
        fill='#817F8F'
        d='M.531 3.75L3.562.703c.157-.14.391-.14.532 0l.36.36c.14.14.14.375 0 .53L2.046 4l2.406 2.422c.14.156.14.39 0 .531l-.36.36a.387.387 0 01-.53 0L.53 4.265a.368.368 0 010-.516z'
      ></path>
    </svg>
  );
};
