import React from "react";

export const HokenLocations = () => {
  return (
    <svg
      width='57'
      height='56'
      viewBox='0 0 57 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48.0783 18.8454C48.4 19.378 47.7927 19.9852 47.2601 19.6635L46.3571 19.1181C46.1677 19.0037 45.9304 19.0037 45.741 19.1181L44.838 19.6635C44.3054 19.9852 43.6981 19.378 44.0198 18.8454L44.5652 17.9424C44.6797 17.7529 44.6797 17.5156 44.5652 17.3262L44.0198 16.4232C43.6981 15.8906 44.3054 15.2833 44.838 15.605L45.741 16.1505C45.9304 16.2649 46.1677 16.2649 46.3571 16.1505L47.2601 15.605C47.7927 15.2833 48.4 15.8906 48.0783 16.4232L47.5329 17.3262C47.4184 17.5156 47.4184 17.7529 47.5329 17.9424L48.0783 18.8454Z'
        fill='#5BFCB8'
      />
      <path
        d='M53.4034 16.5266C53.6254 16.8942 53.2063 17.3133 52.8387 17.0913V17.0913C52.708 17.0123 52.5442 17.0123 52.4135 17.0913V17.0913C52.0459 17.3133 51.6268 16.8942 51.8488 16.5266V16.5266C51.9278 16.3958 51.9278 16.2321 51.8488 16.1013V16.1013C51.6268 15.7337 52.0459 15.3146 52.4135 15.5367V15.5367C52.5442 15.6157 52.708 15.6157 52.8387 15.5367V15.5367C53.2063 15.3146 53.6254 15.7337 53.4034 16.1013V16.1013C53.3244 16.2321 53.3244 16.3958 53.4034 16.5266V16.5266Z'
        fill='#5BFCB8'
      />
      <path
        d='M49.5647 11.467C49.8489 11.9375 49.3125 12.474 48.842 12.1898V12.1898C48.6746 12.0887 48.465 12.0887 48.2976 12.1898V12.1898C47.8271 12.474 47.2907 11.9375 47.5749 11.467V11.467C47.676 11.2996 47.676 11.09 47.5749 10.9227V10.9227C47.2907 10.4521 47.8271 9.91569 48.2976 10.1999V10.1999C48.465 10.301 48.6746 10.301 48.842 10.1999V10.1999C49.3125 9.91569 49.8489 10.4521 49.5647 10.9227V10.9227C49.4636 11.09 49.4636 11.2996 49.5647 11.467V11.467Z'
        fill='#5BFCB8'
      />
      <mask id='path-4-inside-1_27264_33523' fill='white'>
        <path d='M9 9C9 7.89543 9.89543 7 11 7H36C37.1046 7 38 7.89543 38 9V56H9V9Z' />
      </mask>
      <path
        d='M7 9C7 6.79086 8.79086 5 11 5H36C38.2091 5 40 6.79086 40 9H36H11H7ZM38 56H9H38ZM7 56V9C7 6.79086 8.79086 5 11 5V9V56H7ZM36 5C38.2091 5 40 6.79086 40 9V56H36V9V5Z'
        fill='#5BFCB8'
        mask='url(#path-4-inside-1_27264_33523)'
      />
      <path d='M15 15H20' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M27 15H32' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M15 22H20' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M27 22H32' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M15 29H20' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M15 36H20' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M15 43H20' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M27 29H32' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M27 36H32' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M27 43H32' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <mask id='path-16-inside-2_27264_33523' fill='white'>
        <path d='M40 30C40 28.8954 40.8954 28 42 28H55C56.1046 28 57 28.8954 57 30V56H40V30Z' />
      </mask>
      <path
        d='M38 30C38 27.7909 39.7909 26 42 26H55C57.2091 26 59 27.7909 59 30H55H42H38ZM57 56H40H57ZM38 56V30C38 27.7909 39.7909 26 42 26V30V56H38ZM55 26C57.2091 26 59 27.7909 59 30V56H55V30V26Z'
        fill='#5BFCB8'
        mask='url(#path-16-inside-2_27264_33523)'
      />
      <path d='M46 35H51' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M46 40H51' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path d='M46 45H51' stroke='#5BFCB8' stroke-width='2' stroke-linecap='round' />
      <path
        d='M6.16802 27.1819C6.48973 27.7145 5.88248 28.3218 5.34986 28L3.80809 27.0688C3.61864 26.9544 3.38136 26.9544 3.19191 27.0688L1.65014 28C1.11752 28.3218 0.510273 27.7145 0.831981 27.1819L1.76324 25.6401C1.87768 25.4507 1.87768 25.2134 1.76324 25.0239L0.831982 23.4822C0.510274 22.9496 1.11752 22.3423 1.65014 22.664L3.19191 23.5953C3.38136 23.7097 3.61864 23.7097 3.80809 23.5953L5.34986 22.664C5.88247 22.3423 6.48973 22.9496 6.16802 23.4822L5.23676 25.0239C5.12232 25.2134 5.12232 25.4507 5.23676 25.6401L6.16802 27.1819Z'
        fill='#5BFCB8'
      />
    </svg>
  );
};
