import { useAuthentication } from "@hoken/core";

import { UserProfileResponse, UserRefreshInterval } from "./useUser.interface";

import useSWR from "swr";

const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;

export const getIdentityVerificationStatus = (data: UserProfileResponse) =>
  data?.data?.account?.identity_verification_status;

export const getIdentityVerificationUpdateAt = (data: UserProfileResponse) =>
  data?.data?.account?.identity_verification_updated_at;

export const useUser = (options?: { refreshInterval?: UserRefreshInterval }) => {
  const { session, isTypeUser, isTypeAnonymous } = useAuthentication();
  const token = session?.token;
  const endpoint = `${NEXT_PUBLIC_API}/api/v1/me/profile`;
  // TODO: Look into why shorthand property names causes an error on error retry
  const request = isTypeUser ? { endpoint: endpoint, token: token } : null;

  const { data, error, mutate } = useSWR<UserProfileResponse>(request, {
    refreshInterval: options?.refreshInterval,
  });

  // TODO: Refactor isLoading into isUserLoading
  // isLoading is true after useAuthentication confirms the type is user and the user data is being fetched
  const isLoading = isTypeUser && !data;

  // isLoading constant (line above) is used in many places, so we need to keep it as is
  // Because the loading is been validating if the user is authenticated
  // isSessionLoading is validating whether any session is available or not (logged in or not logged in user)
  const isSessionLoading = !session;

  const identityVerificationStatus = getIdentityVerificationStatus(data);
  const emailVerificationStatus = data?.data?.account?.email_verification_status;
  const identityVerificationUpdateAt = getIdentityVerificationUpdateAt(data);

  return {
    data: data?.data,
    isTypeUser,
    isTypeAnonymous,
    isLoading,
    isSessionLoading,
    error,
    token: token,
    identityVerificationStatus,
    identityVerificationUpdateAt,
    emailVerificationStatus,
    mutate,
  };
};
