import React from "react";

export const HokenLocationsMini = () => {
  return (
    <svg
      width='14'
      height='19'
      viewBox='0 0 14 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.8125 0.25C12.7266 0.25 13.5 1.02344 13.5 1.9375V16.5625C13.5 17.5117 12.7266 18.25 11.8125 18.25H8.4375V15.4375C8.4375 14.5234 7.66406 13.75 6.75 13.75C5.80078 13.75 5.0625 14.5234 5.0625 15.4375V18.25H1.6875C0.738281 18.25 0 17.5117 0 16.5625V1.9375C0 1.02344 0.738281 0.25 1.6875 0.25H11.8125ZM2.25 9.8125C2.25 10.1289 2.49609 10.375 2.8125 10.375H3.9375C4.21875 10.375 4.5 10.1289 4.5 9.8125V8.6875C4.5 8.40625 4.21875 8.125 3.9375 8.125H2.8125C2.49609 8.125 2.25 8.40625 2.25 8.6875V9.8125ZM6.1875 8.125C5.87109 8.125 5.625 8.40625 5.625 8.6875V9.8125C5.625 10.1289 5.87109 10.375 6.1875 10.375H7.3125C7.59375 10.375 7.875 10.1289 7.875 9.8125V8.6875C7.875 8.40625 7.59375 8.125 7.3125 8.125H6.1875ZM9 9.8125C9 10.1289 9.24609 10.375 9.5625 10.375H10.6875C10.9688 10.375 11.25 10.1289 11.25 9.8125V8.6875C11.25 8.40625 10.9688 8.125 10.6875 8.125H9.5625C9.24609 8.125 9 8.40625 9 8.6875V9.8125ZM2.8125 3.625C2.49609 3.625 2.25 3.90625 2.25 4.1875V5.3125C2.25 5.62891 2.49609 5.875 2.8125 5.875H3.9375C4.21875 5.875 4.5 5.62891 4.5 5.3125V4.1875C4.5 3.90625 4.21875 3.625 3.9375 3.625H2.8125ZM5.625 5.3125C5.625 5.62891 5.87109 5.875 6.1875 5.875H7.3125C7.59375 5.875 7.875 5.62891 7.875 5.3125V4.1875C7.875 3.90625 7.59375 3.625 7.3125 3.625H6.1875C5.87109 3.625 5.625 3.90625 5.625 4.1875V5.3125ZM9.5625 3.625C9.24609 3.625 9 3.90625 9 4.1875V5.3125C9 5.62891 9.24609 5.875 9.5625 5.875H10.6875C10.9688 5.875 11.25 5.62891 11.25 5.3125V4.1875C11.25 3.90625 10.9688 3.625 10.6875 3.625H9.5625Z'
        fill='#5BFCB8'
      />
    </svg>
  );
};
